import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ComponentPropsWithoutRef, FC } from 'react'

type StyleProps = Pick<PageContainerProps, 'background' | 'shadow'>

const useStyles = makeStyles<Theme, StyleProps>(
  ({ breakpoints, palette, spacing }) => ({
    root: {
      padding: spacing(12, 0, 15),
      boxShadow: ({ shadow }) =>
        shadow ? '0 1px 6px rgba(182, 182, 182, 0.5)' : 'none',
      backgroundColor: ({ background }) =>
        background === 'paper'
          ? palette.background.paper
          : palette.background.default,
      [breakpoints.down('sm')]: {
        padding: spacing(4),
      },
      [breakpoints.down('xs')]: {
        padding: spacing(4, 2),
      },
    },
    inner: {
      maxWidth: 1120,
      margin: '0 auto',
      [breakpoints.down('md')]: {
        maxWidth: 900,
      },
      [breakpoints.down('sm')]: {
        maxWidth: 'unset',
      },
    },
  })
)

type PageContainerProps = ComponentPropsWithoutRef<'section'> & {
  background?: 'paper' | 'default'
  shadow?: boolean
}

export const PageContainer: FC<PageContainerProps> = ({
  children,
  className,
  background = 'paper',
  shadow = false,
  ...rest
}) => {
  const classes = useStyles({ background, shadow })

  return (
    <div className={classes.root} {...rest}>
      <div className={clsx(classes.inner, className)}>{children}</div>
    </div>
  )
}
